.logo {
    margin-top: 45px;
}

.mt-35 {
    margin-top: 35px;
}

.btnBox {
    margin-top: 65px;
    margin-bottom: 50px;
}

html, body, main {
    background-color: #f3f3f3;
}

input[type="text"],
input[type="password"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #bad0e5;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    direction: ltr;
}

input[type="text"]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

input[type="password"]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.container {
    max-width: 800px;
    direction: rtl;
}

#set-customer-btn {
    width: 181px;
    height: 41px;
    border: 0;
    border-radius: 10px;
    color: #fefefe;
    background-color: #001518;
}

#set-customer-btn:disabled {
    cursor: not-allowed;
    color: #e5e5e5;
    background-color: #545454;
}

.form-label {
    width: 161px;
    height: 16px;
    font-family: Nunito;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #808080;
}

.unsubscribe-title {
    margin-top: 72px;
    margin-bottom: 32px;
    font-family: LucidaGrande;
    text-align: center;
}

.w-80{
    width: 80%;
}

#unsubscribe-success {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    width: 100%;
}

.card {
    min-height: 530px;
}

@media (max-width: 500px) {
    .logo {
        margin-top: 15px;
    }

    .card {
        min-height: 412px;
    }

    #unsubscribe-success {
        margin-top: 25px;
    }
}